.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

@supports (height: 100dvh) {
    .App {
        height: 100dvh;
    }
}

.floatContainerTop {
    position: absolute;
    top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.floatContainerBottom {
    position: absolute;
    bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.floatContainer {
    position: absolute;
}

.arrow {
    transition-duration: 5000ms;
    transition-timing-function: ease-out;
}

.questionBox {
    width: 90vw;
    max-width: 600px;
    background-color: white;
    border: 5px solid #373244;
    border-radius: 16px;
    padding: 16px;
    margin-top: 8px;
    transition-duration: 500ms;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader {
    margin-bottom: 16px;
}

.questionBox p {
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 1.1em;
}

button {
    padding-top: 10px;
    padding-bottom: 7px;
    background-color: #373244;
    border: 2px solid white;
    color: white;
    border-radius: 8px;
    font-size: large;
    font-weight: 600;
    margin: 4px;
    padding-left: 16px;
    padding-right: 16px;
    font-family: 'roc-grotesk';
}

button:disabled {
    opacity: 0.5;
}

img {
    user-select: none;
}

.infoContainer {
    width: 100vw;
    max-width: 600px;
    padding: 16px;
}

.bottomSheet {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

li {
    margin-bottom: 12px;
}

.packOuterContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-top: 16px;
}

.packContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.packContainer img {
    margin-bottom: 8px;
    padding: 4px;
    border-radius: 8px;
}
.packContainer p {
    margin-top: 0;
    font-size: small;
}

.imageSelected {
    border: 4px solid #9e99ab;
}

.madebyContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.madebyContainer * {
    margin-bottom: 0;
}
